import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  Paper,
  Container,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import IoSend from '@mui/icons-material/Send';
import Axios from "axios";

const PREFIX = 'test';
const classes = {
      root: `${PREFIX}-root`,
      cta: `${PREFIX}-cta`,
      content: `${PREFIX}-content`,
      chat: `${PREFIX}-chat`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    color : theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main
  },
  [`& .${classes.cta}`]: {
    borderRadius: theme.shape.radius
  },
  [`& .${classes.content}`]: {
    color: theme.palette.common.white,
    fontSize: 16,
    lineHeight: 1.7
  },
  [ `& .${classes.chat}`] : {
     background : "green"
  }

}))


const ChatContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: "80vh",
  display: "flex",
  flexDirection: "column",
}));

const MessageArea = styled(Box)({
  flexGrow: 1,
  overflowY: "auto",
  marginBottom: "16px",
});

const MessageBubble = styled(Box)(({ theme, isUser }) => ({
  display: "flex",
  justifyContent: isUser ? "flex-end" : "flex-start",
  marginBottom: theme.spacing(1),
}));

const MessageContent = styled(Paper)(({ theme, isUser }) => ({
  padding: theme.spacing(1, 2),
  maxWidth: "70%",
  wordBreak: "break-word",
  backgroundColor: isUser ? theme.palette.primary.light : theme.palette.grey[200],
  color: isUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
}));

const InputArea = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const StyledTextField = styled(TextField)({
  flexGrow: 1,
  marginRight: "8px",
});

const ChatUI = () => {

  // const classes = useStyles();

  const [messages, setMessages] = useState([
    /* { id: 1, text: "Hello!", isUser: false, timestamp: new Date(Date.now() - 300000) },
    { id: 2, text: "Hi there! How are you?", isUser: true, timestamp: new Date(Date.now() - 240000) },
    { id: 3, text: "I'm doing great, thanks for asking. How about you?", isUser: false, timestamp: new Date(Date.now() - 180000) },
    { id: 4, text: "I'm good too. Just working on some projects.", isUser: true, timestamp: new Date(Date.now() - 120000) },
    { id: 5, text: "That sounds interesting! What kind of projects?", isUser: false, timestamp: new Date(Date.now() - 60000) }, */
  ]);
  const [newMessage, setNewMessage] = useState("");
  const messageEndRef = useRef(null);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
    
      const url = process.env.REACT_APP_API_URL;

      console.log ('console log ...... ' ,url)

      Axios.post(`${url}/api/chat-bot/query`, 
        {
        "query" : newMessage
        }, 
        {
         headers: {
           'Access-Control-Allow-Origin': '*' 
        }
      })
      .then(response => {
        
        setTimeout(() => {
          const receivedMsg = {
            id: messages.length + 2,
            text: response.data.response,
            isUser: false,
            timestamp: new Date(),
          };
          setMessages((prevMessages) => [...prevMessages, receivedMsg]);
        }, 1000);

      })
      .catch(error => {
        
      });

      const newMsg = {
        id: messages.length + 1,
        text: newMessage,
        isUser: true,
        timestamp: new Date(),
      };
      
      setMessages([...messages, newMsg]);
      setNewMessage("");

    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
        <Container maxWidth="md"  style={{paddingTop : 50 , paddingBottom : 50 }} >
            <ChatContainer elevation={2}  style={{height : "612px" , border : "1px solid rgb(250, 229, 227)" , borderRadius : "2%", background :"rgba(255, 255, 255, 0.1)"}} >
              <Box display={"flex"} justifyContent={"center"} >
                <Typography variant="h5" gutterBottom  >IArtemisa</Typography>
              </Box>
              <MessageArea>
                {messages.map((message) => (
                  <MessageBubble key={message.id} isUser={message.isUser}>
                    {!message.isUser && (
                      <Avatar
                        src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80"
                        alt="User Avatar"
                        sx={{ marginRight: 1 }}
                      />
                    )}
                    <Box>
                      <MessageContent isUser={message.isUser}>
                        <Typography sx={{textOrientation : "sideways"}} variant="caption">{message.text}</Typography>
                      </MessageContent>
                      <Typography variant="" color="textSecondary">
                        {message.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </Typography>
                    </Box>
                    {message.isUser && (
                      <Avatar
                        src="https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80"
                        alt="Your Avatar"
                        sx={{ marginLeft: 1 }}
                      />
                    )}
                  </MessageBubble>
                ))}
                <div ref={messageEndRef} />
              </MessageArea>
              <InputArea>
                <StyledTextField
                  variant="outlined"
                  placeholder="En que te puedo ayudar?"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                  multiline
                  maxRows={4}
                  aria-label="Message input"
                />
                <Button
                  variant="contained"
                  color="white"
                  endIcon={<IoSend />}
                  onClick={handleSendMessage}
                  aria-label="Enviar"
                >
                  Enviar
                </Button>
              </InputArea>
            </ChatContainer>
          </Container>
  );
};

export default ChatUI;
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Landing from './components/Landing';

function App() {

  console.log ("what is ??? " ,  process.env )


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Landing /> }/> 
{/*           <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
 */}       </Routes>
      </BrowserRouter>
      {/* <Footer /> */}
    </>    
  );
}

export default App;

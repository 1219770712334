import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import ChatUI from './Chatbot';

const Landing = ()  => {
    return (<>
      <div className="container">
        <section className="hero">
            <div className="logo">
                <a href='http://www.whitemirror.cl'>
                        <div className="logo-icon"><img width={50} height={50} src="/icono_wm.png"/></div>
                </a>
                <span className="logo-text">White Mirror</span>
            </div>
            <div className="hero-content">
                <div className="text-content">
                    <h1>Encuentra tu terapia personalizada con <span>IA</span></h1>
                    <div className="buttons">
                        <button className="btn btn-primary">Reserva tu cupo</button>
                        <button className="btn btn-secondary">
                            <a href="/chatbot" style={{textDecoration : "none" , color:  "black"}}>
                                IArtemisa
                            </a>
                        </button>
                    </div>
                </div>
                <div className="hero-image">
                    <img className="octa" 
                    src="/ia_futuristic_teraphy.jpg" width={500} height={400} alt="IA Therapy Interface" />
                </div>
            </div>
            <br/>
            <div className={'center'}>
                <zapier-interfaces-chatbot-embed 
                        is-popup='false' 
                        chatbot-id='cm2v7juot000b6ezp1jvazypi' 
                        height='600px' 
                        width='600px'></zapier-interfaces-chatbot-embed>
            </div>

        </section>
        <section className="benefits">
            <h2 className="benefits-title">
                <div className="benefits-icon">
                    <i className="fas fa-star"></i>
                </div>
                Beneficios que puedes encontrar...
            </h2>
            
            <div className="benefits-grid">
                <div className="benefit-item">
                    <h3>Personalización avanzada:</h3>
                    <p>Mediante nuestro algoritmo de emparejamiento, encuentra el terapeuta que mejor se adapta a ti!</p>
                </div>
                
                <div className="benefit-item">
                    <h3>Sesiones transcritas:</h3>
                    <p>Guardamos un registro de tus sesiones para un análisis preciso y detallado.</p>
                </div>
                
                <div className="benefit-item">
                    <h3>Acceso 24/7:</h3>
                    <p>Con nuestro Chatbot siempre disponible, te brindamos apoyo emocional las 24 horas del día.</p>
                </div>
                
                <div className="benefit-item">
                    <h3>Fácil de usar:</h3>
                    <p>Todo el proceso de reservar y encontrar una sesión es rápido y simple desde nuestra app.</p>
                </div>
            </div>
        </section>

        <section className="contact">
            <h2>Mantengámonos en contacto</h2>
            <p>¡Pronto habrá más para ti!</p>
            <p className="subtitle">Inscríbete y sé el primero en conocer nuestras novedades</p>
            
            <form className="form-grid">
                <div className="form-group">
                    <label for="nombre">Nombre</label>
                    <input type="text" id="nombre" name="nombre" required />
                </div>
                
                <div className="form-group">
                    <label for="apellido">Apellido</label>
                    <input type="text" id="apellido" name="apellido" required />
                </div>
                
                <div className="form-group">
                    <label for="email">Correo</label>
                    <input type="email" id="email" name="email" required />
                </div>
                
                <div className="form-group">
                    <label for="tipo">Yo soy...</label>
                    <div className="select-wrapper">
                        <select id="tipo" name="tipo" required>
                            <option value="">Selecciona una opción</option>
                            <option value="paciente">Paciente</option>
                            <option value="terapeuta">Terapeuta</option>
                            <option value="estudiante">Estudiante</option>
                        </select>
                    </div>
                </div>
            </form>
        </section>
    </div>

    <footer>
        <div className="container">
            <h2 className="footer-title">Contacto</h2>
            <p>SÍGUENOS</p>
            <div className="social-links">
                <a href="#" aria-label="Facebook">
                    <FacebookIcon sx={{ fontSize: 35 }} color="primary"/>
                </a>
                <a href="#" aria-label="Twitter">
                    <TwitterIcon sx={{ fontSize: 35 }} color="primary"/>
                </a>
                <a href="#" aria-label="Instagram">
                    <InstagramIcon sx={{ fontSize: 35 }} color="primary"/>
                </a>
            </div>
            <p>O envíanos un correo a:</p>
            <a href="mailto:mejorsaludmental@gmail.com" className="footer-email">
                mejorsaludmental@gmail.com
            </a>
        </div>
    </footer>
        </>
    )
}

export default Landing;